import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styles from './layout.module.css'
import {motion, AnimatePresence} from 'framer-motion'
import SkipLink from './skip-link'
import '../styles/global.css'

const duration = 0.25

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: duration }
  }
}


const Title = ({children}) => (
  <Link to={'/'}>
    <h1 className={styles.title}>
      {children}
    </h1>
  </Link>
)

const MenuItem = ({children, to}) => (
  <li>
    <Link to={to} activeClassName={styles.active}>
      {children}
    </Link>
  </li>
)

const Navigation = () => (
  <nav className={styles.navigation}>
    <ul>
      <MenuItem to={'/'}>Home</MenuItem>
      <MenuItem to={'/about/'}>About</MenuItem>
    </ul>
  </nav>
)

export default ({children, location, titleText}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <>
      <SkipLink />
      <div className={styles.container}>
        <header className={styles.header}>
          <Title>
            {data.site.siteMetadata.title}
          </Title>
          <Navigation />
        </header>
        <AnimatePresence>
          <motion.div
            key={location.pathname}
            variants={variants}
            initial={'initial'}
            animate={'enter'}
            exit={'exit'}>
            <main id={'main-content'} tabIndex={0} className={styles.mainContent}>
              {children}
            </main>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  )
}
import React from 'react'
import styles from './skip-link.module.css'

export default ({ to }) => {

  const _handleClick = (e) => {
    const id = e.currentTarget.href.split('#')[1]
    const element = document.getElementById(id)
    element.focus()
    e.preventDefault()
  }

  return (
    <a href={to || `#main-content`} onClick={_handleClick} className={styles.skipLink}>
      Skip to main content
    </a>
  )
}